import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import {API_URL} from "../../utils/backend.instance";
import Breadcrumb from "../../layout/breadcrumb";
import Error404 from "../../pages/errors/error404";
import PaginationWrapper from "../pagination/pagination";
import Spinner from "../colis/spinner.gif";
import Alert from "../../redux/Alert";
import {useLocation} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Table,
    Card,
    CardHeader,
    CardBody,
    Form,
    Label,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {getColis} from "../colis/action";
import AppContext from "../../context/Context";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const FinanceRequest = () => {
    const notyf = new Notyf();
    const [data, setData] = useState([]);
    const [blockedDates, setBlockedDates] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    let accessToken = ""
    if (localStorage.access) {
        accessToken = localStorage.access;
    }else {
        window.location.replace(`/signin/`);
    }
    const [startDate, setStartDate] = useState(new Date());

    const [financeData, setfinanceData] = useState();
    const [setId] = useState(0);
    const [formData] = useState({});
    const [ascId, setAscSortId] = useState(true);
    const [timer, setTimer] = useState(null)
    const [loading, setLoading] = useState(false);
    const {
        config: {group}
    } = useContext(AppContext)
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    const getFinanceRequests = (query) =>{
        console.log(query);
    }

    let bl_data = []

    const getBlockData = async () =>{
        setLoading(true);
        const apiUrl = `${API_URL}/fournisseur/block-date/`;

        try {
            const response = await fetch(`${apiUrl}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            data?.results?.forEach(item => {
                if(item.is_active){
                    bl_data.push(new Date(item.date))
                }
            })
            setBlockedDates(bl_data)
            console.log("response.data ", data);
        }catch(err){
            console.log(err);
        }finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getBlockData();
    }, []);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allIds = financeData?.results.map((row) => row?.fournisseur_ins?.id);
            setSelectedRows(allIds);
        } else {
            setSelectedRows([]);
        }
    };

    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(id)) {
            return prevSelectedRows.filter((rowId) => rowId !== id);
            } else {
            return [...prevSelectedRows, id];
            }
        });
    };

    const getFinanceRequestsData = async () =>{
        setLoading(true);
        const apiUrl = `${API_URL}/fournisseur/finance-request/`;

        try {
            const response = await fetch(`${apiUrl}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setfinanceData(data || [])
            console.log("response.data ", data);
        }catch(err){
            console.log(err);
        }finally {
            setLoading(false);
        }
    }

    const CreateFinanceReq = async (f_id) => {
        const apiUrl = `${API_URL}/fournisseur/finance_d/`;
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ fournisseur: f_id }),
          });
          if(response.status === 201){
            notyf.success("Finance Data Successfully created ", f_id)
          }else{
            notyf.error("Error creating Finance Data")
          }
        } catch (error) {
            notyf.error('Error sending data to API:', error);
        }
    }

    const sendSelectedRowsToAPI = async () => {
        selectedRows.forEach((row) => {
            CreateFinanceReq(row);
        })
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const date = startDate.toISOString().split('T')[0];
    
        const apiUrl = `${API_URL}/fournisseur/finance-request/`;
        
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ date }),
          });
          if (!response.ok) {
            throw new Error('Failed to submit');
          }
          getFinanceRequestsData();
        } catch (error) {
            throw new Error('Failed to submit');
        }
      };


    useEffect(() => {
        getFinanceRequestsData();
    }, []);
    let query = useQuery();
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)

        window.location.replace(`/request-finance/?${query}`);
    };

    let count = financeData?.count;
    let user_groups_name = group;

    const from = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__gte");
    };

    const to = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__lte");
    };

    const onAscSort = (e) => {
        const qr = query.toString() + `&ordering=${e.target.id}`;
        setAscSortId(false);
        dispatch(getColis(qr));
        setData(data);
    };

    const onDescSort = (e) => {
        const qr = query.toString() + `&ordering=-${e.target.id}`;
        setAscSortId(true);
        dispatch(getColis(qr));
        setData(data);
    };
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };
    const inputChanged = (e) => {
        query.set("search", e)
        clearTimeout(timer)
        query.set("search", e)
        const newTimer = setTimeout(() => {
            dispatch(getFinanceRequests(query));
        }, 500)

        setTimer(newTimer)
    }
    const filterDate = (date) => {
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
        const isBlocked = blockedDates.some((blockedDate) => {
          const isSameDay =
            blockedDate.getDate() === date.getDate() &&
            blockedDate.getMonth() === date.getMonth() &&
            blockedDate.getFullYear() === date.getFullYear();
      
          console.log(`Comparing ${date} with ${blockedDate}: ${isSameDay}`);
          return isSameDay;
        });
        return !isWeekend && !isBlocked;
    };
    return (
        <Fragment>
            <Breadcrumb parent="financeRequest"/>
            {(user_groups_name && user_groups_name.indexOf("admin") !== -1) ||
            (user_groups_name && user_groups_name.indexOf("fournisseur") !== -1) ? (
                <div>
                    <Container fluid={true}>
                        <Alert/>
                        {user_groups_name && user_groups_name.indexOf("fournisseur") !== -1 ? (
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm="3"></Col>
                                                <Col sm="6">
                                                    <Form onSubmit={(e) => onSubmit(e)}>
                                                        <Label>Choisir Un Fournisseur</Label>
                                                        <Row>
                                                            <Col>
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    filterDate={filterDate}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <button
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        setId(formData);
                                                                    }}
                                                                    className="btn "
                                                                    style={{backgroundColor: "#FDF203"}}
                                                                >
                                                                    Ajouter
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <Row>
                                        <Col>
                                            <CardHeader>
                                                <Row>

                                                    <Col sm={"8"}>
                                                        <Row>
                                                            <div className="m-4">
                                                                <h4>Du</h4>
                                                                <input
                                                                    className="btn btn-light"
                                                                    type="date"
                                                                    name="created_at__gte"
                                                                    defaultValue={from()}
                                                                    onChange={(e) => query.set("created_at__gte", e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="m-4">
                                                                <h4>A</h4>
                                                                <input
                                                                    className="btn btn-light"
                                                                    type="date"
                                                                    name="created_at__lte"
                                                                    defaultValue={to()}
                                                                    onChange={(e) => query.set("created_at__lte", e.target.value)}
                                                                />
                                                                <button
                                                                    type="submit"
                                                                    className="btn text-white ml-2"
                                                                    style={{backgroundColor: "#27B3B0"}}
                                                                >
                                                                    Valider
                                                                </button>

                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                        </Col>
                                    </Row>

                                    <CardBody>
                                        <Col sm="12">
                                            <Row className={"m-1"}>
                                                {user_groups_name && user_groups_name.indexOf("fournisseur") !== -1 ? (
                                                    <></>
                                                ):(
                                                    <Col>
                                                        <button className="btn  btn-success m-1" onClick={sendSelectedRowsToAPI}>Bulk Approve IDs</button>
                                                    </Col>
                                                )}
                                                <div className="search-5">
                                                    <h6>Rechercher:</h6>
                                                </div>

                                                <input
                                                    className=" border search "
                                                    style={{borderRadius: "7px"}}
                                                    type="search"
                                                    placeholder="Rechercher..."
                                                    name="search"
                                                    defaultValue={getValSearch()}
                                                    onChange={(e) => inputChanged(e.target.value)}
                                                />
                                            </Row>
                                            <Card>
                                                <div className="card-block row">
                                                    <Col sm="12" lg="12" xl="12">
                                                        <div className="table-responsive">
                                                            {loading ? (
                                                                <div className="text-center">
                                                                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                                </div>
                                                            ):(<></>)}
                                                            {financeData ? (
                                                                <>
                                                                <Table striped>
                                                                    <thead>
                                                                    <tr>
                                                                    {user_groups_name && user_groups_name.indexOf("fournisseur") !== -1 ? (
                                                                        <></>
                                                                    ):(
                                                                        <th>
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={handleSelectAll}
                                                                                checked={selectedRows.length === financeData?.results.length}
                                                                            />
                                                                        </th>
                                                                    )}
                                                                        <th
                                                                            id="id"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Id"}
                                                                        </th>
                                                                        <th
                                                                            id="user_id"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"User ID"}
                                                                        </th>
                                                                        <th
                                                                            id="created_at"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Date"}
                                                                        </th>

                                                                        <th
                                                                            id="nombre_de_colis"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Nombre de colis"}
                                                                        </th>
                                                                        <th
                                                                            id="name_fournisseur"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Fournisseur"}
                                                                        </th>
                                                                        <th
                                                                            id="username"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Nom d'utilisateur"}
                                                                        </th>
                                                                        <th
                                                                            id="total_cod"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Total COD"}
                                                                        </th>
                                                                        <th
                                                                            id="cous_livraison"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Cout total de livraison"}
                                                                        </th>
                                                                        <th
                                                                            id="total_hfl"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Total HFL"}
                                                                        </th>

                                                                        <th>{"Status"}</th>
                                                                        <th>{"Action"}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {financeData &&
                                                                        financeData?.results?.map((b) => (
                                                                            <tr key={b.id}>
                                                                                {user_groups_name && user_groups_name.indexOf("fournisseur") !== -1 ? (
                                                                                    <></>
                                                                                ):(
                                                                                <td>
                                                                                    <input
                                                                                    type="checkbox"
                                                                                    checked={selectedRows.includes(b?.fournisseur_ins?.id)}
                                                                                    onChange={() => handleSelectRow(b?.fournisseur_ins?.id)}
                                                                                    />
                                                                                </td>
                                                                                )}
                                                                                <td>
                                                                                    <Link to={`/finance/${b.id}`}>
                                                                                        {b.id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td>
                                                                                    <Link to={`/fournisseur/edit/${b?.fournisseur_ins?.id}`}>
                                                                                        {b?.fournisseur_ins?.id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td>{b?.created_at?.replace('T', ' at ').replace(/\..*/, '')}</td>
                                                                                <td>{b?.nombre_de_colis}</td>
                                                                                <td>{b?.fournisseur_ins?.name}</td>
                                                                                <td>{b?.user?.username}</td>
                                                                                <td>{Math.round(b.total_cod * 100) / 100}</td>
                                                                                <td>{Math.round(b.cous_livraison * 100) / 100}</td>
                                                                                <td>{Math.round(b.total_hfl * 100) / 100}</td>
                                                                                <td>
                                                                                    {b?.status === 'pending' ? (
                                                                                        <Link
                                                                                            to={`#`}
                                                                                            className="btn btn-red m-1"
                                                                                            style={{ backgroundColor: 'red', color: 'white' }}
                                                                                        >
                                                                                            {b?.status}
                                                                                        </Link>
                                                                                    ): b.status === 'processing'?(
                                                                                        <Link
                                                                                            to={`#`}
                                                                                            className="btn  btn-danger m-1"
                                                                                            style={{ backgroundColor: 'red', color: 'white' }}
                                                                                        >
                                                                                            {b?.status}
                                                                                        </Link>
                                                                                    ): b.status === 'paid'?(
                                                                                        <Link
                                                                                            to={`#`}
                                                                                            className="btn  btn-success m-1"
                                                                                            style={{ backgroundColor: 'green', color: 'white' }}
                                                                                        >
                                                                                            {b?.status}
                                                                                        </Link>
                                                                                    ):(<></>)}
                                                                                </td>
                                                                                <td>
                                                                                    <td>
                                                                                        <Link
                                                                                            to={`/finance_f/${b?.fournisseur_ins?.id}`}
                                                                                            className="btn  btn-primary"
                                                                                        >
                                                                                            Details
                                                                                        </Link>
                                                                                    </td>
                                                                                    {user_groups_name && user_groups_name.indexOf("fournisseur") !== -1 ? (
                                                                                        <></>
                                                                                    ):(
                                                                                    <td>
                                                                                        <Link
                                                                                            to={`/request-finance/edit/${b?.id}`}
                                                                                            className="btn  btn-primary"
                                                                                        >
                                                                                            Edit
                                                                                        </Link>
                                                                                    </td>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    <tr>
                                                                        <td></td>
                                                                    </tr>
                                                                    </tbody>
                                                                </Table>
                                                                
                                                                </>
                                                            ): (<></>)}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>{" "}
                                        <Row>
                                            <Col sm="10"></Col>
                                            {financeData ?(
                                                <Col>
                                                    <PaginationWrapper
                                                        name="Finances"
                                                        length={financeData?.results?.length}
                                                        count={count}
                                                        currentPage={pagenumber}
                                                        onNavigate={Navigate}
                                                    />
                                                </Col>
                                            ):(<></>)}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <Error404/>
            )}
        </Fragment>
    );
};

export default FinanceRequest;